
import { defineComponent } from "vue";
import { Filter } from "@/types/filter.type";
import { AlertStation } from "@/types/alertStation.type";
import AlertStationFormComponent from "@/components/alerts/AlertStationForm.component.vue";
import { Action, ElMessage, ElMessageBox } from "element-plus";
import StationTagComponent from "@/components/stations/StationTag.component.vue";
import {
  changeStationAlertActive,
  deleteStationAlert,
  getStationAlertPage,
} from "@/service/stationalert.service";

const successAction = "success.action";

export default defineComponent({
  components: {
    AlertStationFormComponent,
    StationTagComponent,
  },
  props: {
    alertId: {
      type: Number,
    },
    detailRouterName: {
      type: String,
    },
    list: {
      type: Object as () => AlertStation[],
    },
  },
  data() {
    return {
      query: "" as string,
      alerts: [] as AlertStation[],
      hasData: true,
      showAlertForm: false,
      showAlertInfo: false,
      alertSelected: undefined as undefined | AlertStation,
      currentPage: 1 as number,
      rowPerPage: 10 as number,
      totalRows: 0 as number,
    };
  },
  mounted() {
    this.refreshAlert();
  },
  methods: {
    closeAlertDialog() {
      this.showAlertForm = false;
      this.showAlertInfo = false;
      this.alertSelected = undefined;
    },
    async refreshAlert(item: AlertStation | undefined = undefined) {
      let index = -1;
      if (this.alerts && item) {
        index = this.alerts.findIndex((i) => i.id === item.id);
      }
      if (index !== -1 && item) {
        this.alerts[index] = item;
      } else {
        const filter: Filter = {};
        if (this.query) {
          filter.multi = this.query;
        }
        const pagination = await getStationAlertPage(this.currentPage, this.rowPerPage, filter);
        this.alerts = pagination.data;
        this.totalRows = pagination.totalRows;
      }
      if (this.alerts.length <= 0) {
        this.hasData = false;
        return;
      } else {
        this.hasData = true;
      }
      return this.alerts;
    },
    changePaginationSize(size: number): void {
      this.rowPerPage = size;
      this.refreshAlert();
    },
    changePaginationPage(page: number): void {
      this.currentPage = page;
      this.refreshAlert();
    },
    async deactivateAlert(item: AlertStation) {
      if (item && item.active !== undefined) {
        const typeMessage = item.active ? "activateAlert" : "deactivateAlert";
        ElMessageBox.confirm(
          this.$t("confirm." + typeMessage, { name: item.name }),
          this.$t("label.warning"),
          {
            confirmButtonText: this.$t("btn.accept"),
            cancelButtonText: this.$t("btn.cancel"),
            type: "warning",
            dangerouslyUseHTMLString: true,
            callback: (action: Action) => {
              if (action === "confirm" && item.id && item.active !== undefined) {
                changeStationAlertActive(item.id, item.active).then(() => {
                  ElMessage.success(this.$t(successAction));
                  this.refreshAlert();
                });
              } else {
                item.active = !item.active;
                this.refreshAlert();
              }
            },
          }
        );
      }
    },
    openEntityFormDialog(item: AlertStation | undefined = undefined) {
      this.alertSelected = item;
      this.showAlertForm = true;
    },
    async deleteAlert(item: AlertStation | undefined = undefined) {
      if (item && item.id) {
        deleteStationAlert(item.id).then(() => {
          ElMessage.success(this.$t(successAction));
          this.refreshAlert();
        });
      }
    },
    async saveAlert() {
      // - call alertForm save method
      const alert = await (
        this.$refs["alertStationForm"] as InstanceType<typeof AlertStationFormComponent>
      ).doSave();

      // - close dialog and refresh
      this.closeAlertDialog();
      this.refreshAlert();
      ElMessage.success(this.$t(successAction));
    },
  },
});
