
import { defineComponent } from "@vue/runtime-core";
import { AppError } from "@/types/app.type";
import { Station } from "@/types/station.type";
import { AlertStation, ParamAlertStation } from "@/types/alertStation.type";
import { getStations } from "@/service/station.service";
import {
  getStationAlertById,
  postStationAlert,
  putStationAlert,
  deleteParameterStationAlert,
} from "@/service/stationalert.service";
import { Action, ElForm, ElMessage, ElMessageBox } from "element-plus";
import { getStationParameters, StationParameter } from "@/service/stationparameter.service";

const errorMessage = "error.required";

interface AlertRow {
  value: number | string;
  status: boolean;
}

export default defineComponent({
  components: {},
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      alertForm: {} as AlertStation,
      alertArray: [] as AlertStation[],
      alertFormList: {} as ParamAlertStation,
      alertList: [] as ParamAlertStation[],
      newAlertList: [] as ParamAlertStation[],
      removeAlertList: [] as ParamAlertStation[],
      stations: [] as Station[],
      paramList: [] as StationParameter[],
      operList: [">", "<", "=", "≠"],
      rules1: {
        // station: [
        //   {
        //     required: true,
        //     message: this.$t("error.required"),
        //     trigger: blur,
        //   },
        // ],
        parameter: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
        value: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
        operator: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
      },
      rules2: {
        name: [
          {
            required: true,
            message: this.$t(errorMessage),
            trigger: blur,
          },
        ],
      },
    };
  },
  methods: {
    async saveAlert() {
      //Redondear el value antes de subir
      this.fixValue(this.alertFormList);

      let valid;
      try {
        valid = await (this.$refs["form1"] as InstanceType<typeof ElForm>).validate();
      } catch (error) {
        valid = false;
      }
      if (!valid) {
        throw new Error("validation");
      } else {
        const newAlert = {} as ParamAlertStation;
        newAlert.station = this.alertFormList.station;
        newAlert.parameter = this.alertFormList.parameter;
        if (
          this.alertFormList.parameter &&
          (this.alertFormList.parameter.value === "gps" ||
            this.alertFormList.parameter.value === "angle")
        ) {
          newAlert.value = -1;
        } else {
          newAlert.value = this.alertFormList.value;
        }
        newAlert.operator = this.alertFormList.operator;
        newAlert.compositeOperator = "AND";

        this.alertList.push(newAlert);
        this.newAlertList.push(newAlert);
        if (this.alertForm.id === undefined) {
          this.alertForm.parameterStationAlerts?.push(newAlert);
        }
      }
    },
    async doSave() {
      if (this.alertList.length === 0) {
        ElMessage.error(this.$t("error.NO_ALERTS"));
        throw new Error(AppError.FORM_VALIDATION);
      } else if (this.alertForm.name === undefined || this.alertForm.name.length === 0) {
        ElMessage.error(this.$t("error.no_name"));
        throw new Error(AppError.FORM_VALIDATION);
      }

      let response;
      this.alertForm.parameterStationAlerts = this.alertList;

      if (this.alertForm.id === undefined) {
        console.log("Formulario de alerta antes de enviar:", this.alertForm);
        response = await postStationAlert(this.alertForm);
        this.alertArray.push(response);
      } else {
        // Si se ha borrado una condición que ya estaba creada
        if (this.removeAlertList.length > 0) {
          await deleteParameterStationAlert(this.removeAlertList);
        }

        // Se guardan solo las nuevas condiciones
        this.alertForm.parameterStationAlerts = [];
        this.alertForm.parameterStationAlerts = this.newAlertList;
        response = await putStationAlert(this.alertForm.id, this.alertForm);

        this.alertArray.push(response);
      }

      return response;
    },
    deleteAlert(item: ParamAlertStation) {
      var i = this.alertList.indexOf(item);
      if (i !== -1) {
        this.alertList.splice(i, 1);
        this.removeAlertList.push(item);
      }

      var index = this.newAlertList.indexOf(item);
      if (index !== -1) {
        this.newAlertList.splice(index, 1);
      }
    },
    checkSelectedParameter(newValue: StationParameter) {
      if (newValue.value != "gps" && newValue.value != "angle") {
        this.operList = [">", "<", "=", "%", "≠"];
        this.rules1.value[0].required = true;
      } else {
        this.rules1.value[0].required = false;
        this.operList = ["=", "≠"];
        this.alertFormList.operator = "≠";
        this.alertFormList.value = undefined;
      }
    },
    showEdit(item: ParamAlertStation) {
      item.status = true;
    },
    closeEdit(item: ParamAlertStation) {
      item.status = false;
    },
    async edit(item: ParamAlertStation) {
      item.status = false;
      this.fixValue(item);
      const i = this.alertList.findIndex((index) => index.id === item.id);
      this.newAlertList.splice(i, 1);
      this.newAlertList.push(item);
    },

    fixValue(item: ParamAlertStation) {
      if (item.value) {
        console.log("Fixed");
        item.value = parseFloat(item.value.toFixed(2));
      }
    },
  },
  async mounted() {
    const response = await getStations();
    this.stations = response.data;
    const parametersPagination = getStationParameters();
    this.paramList = parametersPagination;
    if (this.id) {
      this.alertForm = await getStationAlertById(this.id);
      if (this.alertForm.parameterStationAlerts) {
        this.alertList = [];
        this.alertList = this.alertForm.parameterStationAlerts;
        this.alertList.forEach((_) => {
          _.parameter = this.paramList.find((param) => param.value === _.magnitude);
          _.status = false;
        });
      }
    }
  },
});
