
import { defineComponent } from "vue";
import { AlertStation } from "@/types/alertStation.type";
import PageButtonsComponent from "@/components/PageButtons.component.vue";
import AlertStationFormComponent from "@/components/alerts/AlertStationForm.component.vue";
import AlertStationListComponent from "@/components/alerts/AlertStationList.component.vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  components: {
    PageButtonsComponent,
    AlertStationFormComponent,
    AlertStationListComponent,
  },
  data() {
    return {
      showMap: false,
      showAlertForm: false,
      alertForm: undefined as undefined | AlertStation,
      alertsList: [],
    };
  },
  methods: {
    openAlertFormDialog() {
      this.showAlertForm = true;
    },
    closeAlertFormDialog() {
      this.showAlertForm = false;
    },
    saveAlertForm() {
      (this.$refs["alertStationForm"] as InstanceType<typeof AlertStationFormComponent>)
        .doSave()
        ?.then(() => {
          this.closeAlertFormDialog();
          (
            this.$refs["alertStationList"] as InstanceType<typeof AlertStationListComponent>
          ).refreshAlert();
          ElMessage.success(this.$t("success.action"));
        })
        .catch(() => void 0);
    },
  },
});
