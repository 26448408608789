import { AlertStation, ParamAlertStation } from "./../types/alertStation.type";
import { Filter } from "./../types/filter.type";
import { Pagination } from "./../types/pagination.type";
import { responseHandlerDefault } from "./../utils/handler.util";
import axios from "./api";

export const getStationAlertPage = (
  page: number | undefined,
  size: number | undefined,
  filter?: Filter
): Promise<Pagination<AlertStation>> => {
  const params: { page?: number; size?: number; filter?: Filter } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }

  return axios.get(`/stationalert`, { params }).then(responseHandlerDefault);
};

export const getStationAlertById = async (alertId: number): Promise<AlertStation> => {
  return axios.get(`/stationalert/${alertId}`).then(responseHandlerDefault);
};

export const postStationAlert = (alert: AlertStation): Promise<AlertStation> => {
  return axios.post(`/stationalert`, alert).then(responseHandlerDefault);
};

export const deleteStationAlert = async (alertId: number): Promise<void> => {
  return axios.delete(`/stationalert/${alertId}`).then(responseHandlerDefault);
};

export const changeStationAlertActive = (
  alertId: number,
  active: boolean
): Promise<AlertStation> => {
  if (active) {
    return axios.put(`/stationalert/${alertId}/activate`).then(responseHandlerDefault);
  } else {
    return axios.put(`/stationalert/${alertId}/deactivate`).then(responseHandlerDefault);
  }
};
export const putStationAlert = (alertId: number, alert: AlertStation): Promise<AlertStation> => {
  return axios.put(`/stationalert/${alertId}`, alert).then(responseHandlerDefault);
};

export const deleteParameterStationAlert = (alert: ParamAlertStation[]): Promise<void> => {
  return axios.post(`/stationalert/remove/`, alert).then(responseHandlerDefault);
};
