
import { AlertStation } from "@/types/alertStation.type";
import { defineComponent } from "@vue/runtime-core";
import { getStationNames } from "@/service/paramaeterStationAlert.service";

export default defineComponent({
  props: {
    item: {
      type: Object as () => AlertStation,
      required: true,
    },
  },
  data() {
    return {
      codePoints: [] as string[],
      total: 0 as number,
      parameterAlerts: [] as any[],
    };
  },
  mounted() {
    this.refreshParameter();
  },
  methods: {
    async refreshParameter() {
      this.parameterAlerts = await getStationNames(this.item);
    },

    showName(index: number, name: string) {
      let namePoint = name;
      if (namePoint.length > 15) {
        namePoint = `${name.substring(0, 15)}...`;
      }

      return index <= 1 ? namePoint : "+";
    },
  },
  watch: {
    item: function () {
      this.refreshParameter();
    },
  },
});
