import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parameterAlerts, (parameterAlert, index) => {
    return (_openBlock(), _createBlock(_component_el_tag, {
      class: "alertName",
      key: parameterAlert
    }, {
      default: _withCtx(() => [
        parameterAlert
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.showName(index, parameterAlert.station_name)), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, "Cualquier estación"))
      ]),
      _: 2
    }, 1024))
  }), 128))
}