
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    direction: {
      type: String as PropType<"horizontal" | "vertical">,
      default: "vertical",
    },
  },
});
