export interface StationParameter {
  value: string;
  label: string;
  color?: string;
  graph: boolean;
}

export interface GroupedStationParameters {
  label: string;
  options: StationParameter[];
}

const parameters: GroupedStationParameters[] = [
  {
    label: "Parámetros operacionales",
    options: [
      {
        value: "voltage",
        label: "Batería",
        color: "#F5D491",
        graph: true,
      },
      {
        value: "rssi",
        label: "RSSI",
        color: "#F06C9B",
        graph: true,
      },
      {
        value: "available_SD",
        label: "Espacio SD",
        color: "#F46036",
        graph: true,
      },
      {
        value: "optical_operation",
        label: "Funcionamiento óptico",
        color: "#B2945B",
        graph: true,
      },
      {
        value: "state_SD",
        label: "Estado SD",
        color: "#C5D86D",
        graph: true,
      },
      {
        value: "capture",
        label: "Guardado en la SD",
        color: "#1B998B",
        graph: true,
      },
    ],
  },
  {
    label: "Parámetros ambientales",
    options: [
      {
        value: "luminosity",
        label: "Luminosidad",
        color: "#FFD166",
        graph: true,
      },
      {
        value: "temp_ext",
        label: "Temperatura exterior",
        color: "#E9806E",
        graph: true,
      },
      {
        value: "temp_water",
        label: "Temperatura del agua",
        color: "#26547C",
        graph: true,
      },
      {
        value: "atmospheric_pressure",
        label: "Presión atmosférica",
        color: "#C59B76",
        graph: true,
      },
      {
        value: "humidity_ext",
        label: "Humedad exterior",
        color: "#61A0AF",
        graph: true,
      },
      {
        value: "distance_water",
        label: "Distancia del agua",
        color: "#96C9DC",
        graph: true,
      },
      {
        value: "gps",
        label: "GPS",
        graph: false,
      },
      {
        value: "angle",
        label: "Ángulo",
        graph: false,
      },
    ],
  },
];

export const getStationParameters = (): StationParameter[] => {
  return parameters.map((sets) => sets.options).flat();
};

export const getGroupedStationParameters = (): GroupedStationParameters[] => {
  return parameters;
};
